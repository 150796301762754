var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseMapInfo',{attrs:{"label":_vm.detail.properties.label? _vm.detail.properties.label : 'Новый субъект',"detail":_vm.detail},scopedSlots:_vm._u([{key:"tooltips",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"caption d-block"},on),[(_vm.showNewSideBarButton)?_c('BaseBtn',{attrs:{"icon":"mdi-eye-check","disabled":!_vm.$can('admin', null),"loading":_vm.isLoading,"text":"","color":"primary","isTypeIcon":""},on:{"click":function($event){return _vm.$emit('input', 'mapPassportNavigationSideBar')}}}):_vm._e()],1)]}}])},[_vm._v(" Перейти в панель \"Список доступных паспортов\" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.isAllowSaveDelete)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"caption d-block"},on),[_c('BaseBtn',{attrs:{"icon":"mdi-content-save-outline","disabled":!_vm.$can('admin', null),"loading":_vm.isLoading,"text":"","color":"primary","isTypeIcon":""},on:{"click":_vm.handleSave}})],1)]}}:null],null,true)},[_vm._v(" "+_vm._s(_vm.$t('base.save'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.isAllowSaveDelete)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"caption d-block"},on),[_c('BaseBtn',{attrs:{"icon":"mdi-delete-empty-outline","disabled":!_vm.$can('admin', null),"text":"","color":"info","isTypeIcon":""},on:{"click":function($event){_vm.deleteDialog = !_vm.deleteDialog}}})],1)]}}:null],null,true)},[_vm._v(" "+_vm._s(_vm.$t('passport.deleteRegion'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"caption d-block"},on),[_c('BaseBtn',{attrs:{"icon":"mdi-close","text":"","color":"info","isTypeIcon":""},on:{"click":_vm.handleClose}})],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('passport.closePassport'))+" ")])]},proxy:true},{key:"tabs",fn:function(){return [_c('v-divider'),_c('v-tabs',{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.items),function(item){return _c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 1),expression:"items.length > 1"}],key:item.tab},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"caption d-block "},on),[_c('v-icon',[_vm._v(_vm._s(item.tab))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.hint)+" ")])],1)}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.tab},[_c(item.content,{tag:"component",attrs:{"prepend-inner-icon":item.prependInnerIcon,"is-loading":item.isLoading},model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}})],1)}),1)],2)]},proxy:true},{key:"dialog",fn:function(){return [_c('v-divider'),_c('BaseDialog',{attrs:{"role":"delete","text":_vm.$t('message.deleteRegion'),"is-cancel-btn":"","is-confirm-btn":"","max-width":350},on:{"confirm":_vm.handleDelete},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }