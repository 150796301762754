export default {
  data () {
    return {
      hiddenList: {}, //id паспортов, которые надо скрыть
      hiddenPassportsLayer: [] //слои со скрытыми паспортами, чтобы их вернуть
    }
  },
  methods: {
    hidePassport (event) {
      const model = this.modelStatic._getActiveModel(this.info.layer)
      const layer = model.layers.findByDetailId(event)
      if (this.hiddenList.hasOwnProperty(event.properties.id)) { //если в hiddenList есть этот паспорт, значит он скрыт и его надо вернуть
        // delete this.hiddenList[event.properties.id]
        this.$delete(this.hiddenList, event.properties.id)
        this.map.addLayer(layer)
      } else { //если нет, значит скрыть
        // this.hiddenList[event.properties.id] = true
        this.$set(this.hiddenList, event.properties.id, true)
        this.hiddenPassportsLayer.push(layer)
        layer.remove()
      }
    }
  }
}