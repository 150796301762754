import chroma from 'chroma-js'
//логика взаимодействия с панелью справа для объектов паспорта на карте
export default {
  data () {
    return {
      isReportDialog: false,
      isFiltersObjectsDialog: false,
      itemActiveReport: null,
      itemFilter: null,
      keyReportWindow: 1,
      isAddCoordinatesWindow: false,
      isImportDialog: false,
      currentImportObject: null, //index
      objectPanelKey: 0 // ключ для обновления панели объектов
    }
  },
  watch: {
    itemActiveReport: {
      handler () {
        this.keyReportWindow += 1
      },
      deep: true
    }
  },
  methods: {
    clickCoordinates () {
      this.isAddCoordinatesWindow = true
      return true
    },
    showFilters (item) {
      this.isFiltersObjectsDialog = true
      this.itemFilter = item
    },
    async searchObjects (item) {
      this.info.isLoading = true
      this.$store.commit('map/lastSelected', null)
      this.info.filter[this.itemFilter.eavEntity.id] = item
      this.info.filter[this.itemFilter.eavEntity.id].eav_entity_id = this.itemFilter.eavEntity.id
      this.$store.commit('map/changeFilterObjects', { id: this.itemFilter.eavEntity.id, status: true })
      await this._initLayers()
      this.reRenderLayers()
      this.objectPanelKey += 1
      this.info.isLoading = false
      this.isFiltersObjectsDialog = false
    },
    async clearFilter (eavId) {
      if (this.info.filter[eavId]) {
        for (const key in this.info.filter[eavId]) {
          if (key !== 'eav_entity_id') {
            this.$delete(this.info.filter[eavId], key)
          }
        }
        await this._initLayers()
        this.reRenderLayers()
        this.$store.commit('map/changeFilterObjects', { id: eavId, status: false })
      }
      this.isFiltersObjectsDialog = false
    },
    hideObjects (event) {
      const findItem = this.controlLayers.overlay.find((item) => item.label === event.item.eavEntity.entityName) // итем на который нажали "скрыть/показать"
      const lastSelected = this.$store.getters['map/lastSelected']

      if (event.status && !this.isGeometry) { //если нажали скрыть
        findItem.isShow = false
        findItem.children.forEach((item) => {
          this.map.removeLayer(item.layer)
        })
        if (lastSelected && +lastSelected.feature.properties.eav_entity_id === +event.item.eavEntity.id) {
          this.$store.commit('map/lastSelected', null)
        }
      } else if (!this.isGeometry) { //здесь показать
        findItem.isShow = true
        this.reRenderLayers(this.controlLayers.overlay)
      }
    },
    blockLayer (event) {
      const findItem = this.controlLayers.overlay.find((item) => item.label === event.item.eavEntity.entityName)
      if (event.status && !this.isGeometry) { //заблокировать
        findItem.children.forEach((item) => {
          const leafletid = item.layer._leaflet_id
          const layer = this.map._layers[leafletid]
          layer.options.isBlocked = true
        })
      } else if (!this.isGeometry) { //разблокировать
        findItem.children.forEach((item) => {
          const leafletid = item.layer._leaflet_id
          const layer = this.map._layers[leafletid]
          layer.options.isBlocked = false
        })
      }
    },
    massMove (event) {//args: typeOffsetGeometry - двигать определенный тип - [точки], [полигоны] или [другое]
      // offsetX, offsetY, item: {id:number, eavEntity:{}}
      const item = event.item
      const layers = this.modelStatic._getActiveModel(this.info.layer).selected.children.layers.geoJson.server

      for (let indexGeometry in event.typeOffsetGeometry) {
        const typeGeometry = event.typeOffsetGeometry[indexGeometry]
        const objectsByEav = layers[typeGeometry][item.eavEntity.id]._layers
        const isHasObject = Object.keys(objectsByEav).length

        if (isHasObject) {
          for (const layer in objectsByEav) {
            const latlng = objectsByEav[layer]._latlngs || objectsByEav[layer]._latlng //координаты на карты
            const offsetX = event.offsetX
            const offsetY = event.offsetY

            if (!Array.isArray(latlng)) {
              //для точек
              objectsByEav[layer].setLatLng({ lat: latlng.lat + Number(offsetX), lng: latlng.lng + Number(offsetY) })
            } else {
              //для остальных типов
              const newLatlng = latlng.map((latnlgs, index1) => {
                if (!Array.isArray(latnlgs)) {
                  objectsByEav[layer].feature.geometry.coordinates[index1][0] += Number(offsetY) //изменяю координаты в деталях
                  objectsByEav[layer].feature.geometry.coordinates[index1][1] += Number(offsetX)
                  return { lat: latlng[index1].lat + Number(offsetX), lng: latlng[index1].lng + Number(offsetY) }
                } else { //если массив в массиве в массиве [[[...
                  return latnlgs.map((ll, index2) => {
                    objectsByEav[layer].feature.geometry.coordinates[index1][index2][0] += Number(offsetY)
                    objectsByEav[layer].feature.geometry.coordinates[index1][index2][1] += Number(offsetX)
                    if (index2 === objectsByEav[layer].feature.geometry.coordinates[index1].length - 2) {
                      //для последнего элемента в массиве, в latlng его просто нет и мап не проходит по нему
                      objectsByEav[layer].feature.geometry.coordinates[index1][index2 + 1][0] += Number(offsetY)
                      objectsByEav[layer].feature.geometry.coordinates[index1][index2 + 1][1] += Number(offsetX)
                    }
                    return {
                      lat: latlng[index1][index2].lat + Number(offsetX),
                      lng: latlng[index1][index2].lng + Number(offsetY)
                    }
                  })
                }
              })
              objectsByEav[layer].setLatLngs(newLatlng)
            }
          }
        }
      }
    },
    async saveMassMoved (event) { //args: typeOffsetGeometry - определенный тип - точки, полигоны или другое, item
      this.info.isLoading = true
      const layers = this.modelStatic._getActiveModel(this.info.layer).selected.children.layers.geoJson.server
      for (let indexGeometry in event.typeOffsetGeometry) {
        const typeGeometry = event.typeOffsetGeometry[indexGeometry]
        const objectsByEav = layers[typeGeometry][event.item.eavEntity.id]._layers
        const isHasObject = Object.keys(objectsByEav).length
        if (isHasObject) {
          for (const layer in objectsByEav) {
            const feature = objectsByEav[layer].feature
            const data = await this.$store.dispatch('server/get', {
              url: `object/detail/${feature.properties.id}`
            })
            data.geometry.coordinates = feature.geometry.coordinates
            await this.$store.dispatch('server/save', {
              url: 'object/detail',
              data: { data }
            })
          }
        }
      }
      this.info.isLoading = false
    },
    reRenderLayers (overlay = this.controlLayers.overlay) { // note: костыльный способ
      // время работы функции для большого паспорта (левобережный) примерно 140 мили сек (или 0,002 мин)
      // что происходит: сначала удаляются все слои, затем добавляются заново и только те, которые не нужно скрывать
      overlay.forEach((item) => {
        item.children.forEach((element) => {
          this.map.removeLayer(element.layer) //удалить все слои
        })
      })
      overlay.forEach((item) => {
        item.children.forEach((element) => {
          if (item.isShow) {
            this.updateRadiusObjects()
            this.map.addLayer(element.layer) // добавить все те, которые не скрыты
          }
        })
      })
      return true
    },
    async openReportTable (item) {
      this.countCurrentObjectTable = await this.countObjects(item)
      this.itemActiveReport = item
      this.isReportDialog = true
    },

    clearReportObject (event, schemaId = this.itemActiveReport.eavEntity.id) {
      const carts = this.$store.getters['map/cart'][schemaId]
      carts.forEach((item) => {
        const currentEavObject = this.modelStatic._getActiveModel(this.info.layer).selected.children.layers.geoJson.local[item.geometry.type][schemaId]
        for (let key in currentEavObject._layers) {
          currentEavObject._layers[key].setStyle(this.itemActiveReport.eavEntity.style)
        }
      })
      this.$store.commit('map/clearCart', schemaId)
    },
    handleAddReport (event, schemaId) {
      this.handleAddToCart(event, schemaId) // в стор
      const currentLayer = this.findClickReportObject(event, schemaId)
      currentLayer.setStyle({ color: chroma(this.itemActiveReport.eavEntity.style.color).brighten().hex() })
    },
    handleDeleteReport (event, schemaId) {
      this.handleRemoveFromCart(event, schemaId)
      const currentLayer = this.findClickReportObject(event, schemaId)
      currentLayer.setStyle(this.itemActiveReport.eavEntity.style)
    },
    findClickReportObject (event, schemaId) { // возвращает слой, добавленный или удаленный из отчета
      const currentEavObject = this.modelStatic._getActiveModel(this.info.layer).selected.children.layers.geoJson.local[event.geometry.type][schemaId] //все слои по eav
      let currentLayer = null //слой, который добавился в отчеты
      for (let key in currentEavObject._layers) {
        if (currentEavObject?._layers[key]?.feature?.properties?.id === event?.properties?.id) {
          currentLayer = currentEavObject._layers[key]
        }
      }
      return currentLayer
    },
    showImportDialog (item) {
      this.currentImportObject = item //index
      this.isImportDialog = true
    }
  }
}