var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"ml-3 mr-3 app-bar",attrs:{"color":_vm.color,"height":"44","tile":"","app":"","absolute":""}},[_vm._t("cancelButton",[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"caption d-block"},on),[_c('v-icon',{staticClass:"cancel-button",attrs:{"color":"white","disabled":_vm.disabled},on:{"click":_vm.handleClose}},[_vm._v(" mdi-close ")])],1)]}}])},[_vm._v(" "+_vm._s('Выйти из паспорта')+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"white--text ellipsis-text"},on),[_vm._v(" "+_vm._s(_vm.title)+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('VerificationBtn',{attrs:{"can-verify":_vm.canVerify,"loading":_vm.isLoading},on:{"click":_vm.handleVerificationSend}})],1)]}}])},[_vm._v(" "+_vm._s('Отправить на верификацию')+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-select',{staticClass:"pl-8 pr-8 mt-0 white--text",attrs:{"items":_vm.itemsScenario,"item-text":"title","item-value":"id","outlined":"","hide-details":"","dark":"","dense":""},model:{value:(_vm.currentScenario),callback:function ($$v) {_vm.currentScenario=$$v},expression:"currentScenario"}})],1)]}}])},[_vm._v(" "+_vm._s('Изменить сценарий')+" ")]),_vm._l((_vm.filteredButtons),function(button,index){return _c('div',{key:index,staticClass:"d-flex flex-row"},[(button.if === false ? button.if : true)?_c('BaseBtn',{attrs:{"color":"white","colorIcon":"white","small":"","x-small-icon":"","text":"","class-btn":button.class ? button.class + ' pa-1' : 'pa-1',"disabled":_vm.disabled,"loading":_vm.isLoading,"icon":button.icon,"title":button.title},on:{"click":function($event){return _vm.$emit('click', button.title)}}}):_vm._e(),_vm._t("append")],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }