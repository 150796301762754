<template>
  <BaseSideBar dark>
    <!--:todo Сделать пагинация при необходимости + сохранять выбранный в сайдбаре паспорт в стор-->
    <v-card flat class="sidebar-holder">
      <v-card-text class="sidebar-content">
        <div class="d-flex justify-space-between pb-2">
          <div class="pt-1" v-if="$can('manager') || $can('admin')">
            <v-btn x-small outlined @click="handleCreate">Создать паспорт
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </div>
          <div class="d-flex pt-0 mt-0">
            <BaseBtn small :icon="isDialogFilter ? 'mdi-magnify-close' : 'mdi-magnify'"
              @click="isDialogFilter = !isDialogFilter" :loading="isLoading" isTypeIcon />
          </div>
        </div>
        <div v-if="isDialogFilter">
          <BaseFilter @handleSearch="handleSearch" @clearFilter="handleClear" :is-additional-styles="false">
            <template #filters>
              <FilterPassport :changes-fields="changesFields" solo-inverted hide-details @change="changeField" />
            </template>
          </BaseFilter>
        </div>
        <virtual-list v-if="searchModel.items && searchModel.items.models" class="virtual-list"
          :data-sources="searchModel.items.models" :data-key="getListDataKey" :data-component="Item"
          :extra-props="{hiddenList, select: handleSelect}"
          />
      </v-card-text>
    </v-card>
  </BaseSideBar>
</template>

<script>
import BaseSideBar from '@/components/base/BaseSideBar'
import BaseBtn from '@/components/base/UI/BaseBtn'
import ListItemMiddleware from '@/components/views/account/map/detail/mapPassportNavigationSideBar/ListItemMiddleware'
import BaseFilter from '@/components/base/BaseFilter'
import FilterPassport from '@/components/views/account/passport/common/FilterPassport'
import { verificationScenarioByRole } from '@/components/utils/common'
import { emitEvent } from '@/components/utils/map/common'
import selectFirst from '@/components/mixins/map/selectFirst'
import VirtualList from 'vue-virtual-scroll-list'

export default {
  name: 'MapPassportNavigationSideBar',
  props: { value: Object, hiddenList: Object },
  components: { BaseSideBar, BaseBtn, ListItemMiddleware, BaseFilter, FilterPassport, 'virtual-list': VirtualList  },
  mixins: [selectFirst],
  data () {
    return {
      isLoading: false,
      currentActiveItem: null,
      isDialogFilter: false,
      changesFields: {},
      Item: ListItemMiddleware,
      searchModel: {
        query: {
          filter: {
            verificationScenario: verificationScenarioByRole()
          },
          options: {
            /*page: 1,
            itemsPerPage: 20*/
          }
        },
        moduleName: 'passport',
        items: null
      }
    }
  },
  methods: {
    handleSelect (data) {
      emitEvent('search:select', data)
    },
    getListDataKey (item) {
      return item.properties.id
    },
    //todo: синхронизировать это с загрузкой слоев, сейчас 2 одинаковых запроса летят
    async handleSearch () {
      this.$set(this.searchModel.query, 'filter', { ...this.filter, ...this.changesFields })
      //this.$set(this.searchModel.query.filter, 'region_id', this.value.properties.id)
      if (!this.isLoading) {
        this.isLoading = true

        const response = await this.$store.dispatch('server/get', {
          url: '/' + this.searchModel.moduleName,
          params: this.searchModel.query
        })

        let iterationCount = 1;

        while (response.models.length < response.count){
          const newResponse = await this.$store.dispatch('server/get', {
            url: '/' + this.searchModel.moduleName,
            params: {
              options: {
                page: ++iterationCount,
                itemsPerPage: 1000
              },
              filter: this.searchModel.query.filter
            }
          });

          response.models.push(...newResponse.models)
        }

        this.searchModel.items = response
        this.isLoading = false
        this.selectFirst()
      }
      return true
    },
    handleClear () {
      for (let key in this.changesFields) {
        this.$delete(this.searchModel.query.filter, key)
      }
      this.$set(this, 'changesFields', {})
      this.handleSearch()
      this.isDialogFilter = !this.isDialogFilter
    },
    changeField (data) {
      this.$set(this, 'changesFields', data)
    },
    handleCreate () {
      this.$emit('create', { isCreate: true, type: 'passport' })
    }
  },
  mounted () {
    return this.handleSearch()
  }
}
</script>

<style scoped>
  .sidebar-holder{
    overflow: hidden;

    .sidebar-content{
      height: 100vh; 
      
      .virtual-list{
        overflow-y: auto;
        margin-right: -16px;
        padding-right: 16px;
        height: 100%;
      }
    }
  }
  
</style>
