<template>
    <ListItem :item="source" @hide="$emit('hide', $event)" :hiddenList="hiddenList" @select="select"/>
</template>

<script>
import ListItem from '@/components/views/account/map/detail/mapPassportNavigationSideBar/ListItem'

export default {
    name: 'VirtualListItemWrapper',
    components: { ListItem },
    props: {
        index: Number,
        source: Object,
        hiddenList: Object,
        select: Function
    }
}
</script>