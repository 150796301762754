<template>
  <BaseCard is-small-height>
    <template #content>
      <ObjectList
        v-model="localValue.properties.passportEntities"
        :name="value.properties.object_name"
        @done="$emit('save')"
        :dialog="isOpen"
        @update:dialog="$emit('update:dialog', $event)"
      />
    </template>
  </BaseCard>
</template>

<script>
import ObjectList from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/ObjectList'
import BaseCard from '@/components/base/BaseCard'

export default {
  name: 'MapPassportObjectsList',
  components: { BaseCard, ObjectList },
  props: {
    value: Object,
    isOpen: Boolean
  },
  data () {
    return {
      localValue: null
    }
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
