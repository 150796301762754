export default {
  props: {
    item: Object,
    isSelected: Boolean,
    selectable: Boolean
  },
  computed: {
    localSelected: {
      get () {
        return this.isSelected
      },
      set (val) {
        this.$emit('input', { item: this.item, isSelected: val })
      }
    }
  }
}
