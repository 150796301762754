<template>
  <BaseDialog
    max-width="100%"
    :value="isDialog"
    @input="toggleMainDialog"
    :is-nested-dialog="isNestedDialog"
    is-cross-close
    label=" "
  >
    <template #content>
      <div
        class="d-flex flex-row"
        style="gap: 5px"
        v-if="countObjects"
      >
        <BaseBtn
          v-if="selectedItems.length"
          icon="mdi-text-box-multiple"
          x-small
          :title="$t('base.createReport')"
          @click="toggleDialogs('dialogReport', true)"
          :loading="isLoading"
        />
        <BaseBtn
          v-if="selectedItems.length"
          x-small
          :title="$t('base.clearSelected')"
          @click="handleClear"
          :loading="isLoading"
        />
        <BaseBtn
          icon="mdi-cog"
          x-small
          color="primary"
          :title="$t('base.reportWithSettings')"
          @click="toggleDialogs('dialogWithSettings', true)"
          :loading="isLoading"
        />
      </div>
      <BaseReportPanel
        v-model="dialogReport"
        report-type="object"
        :eav-entity-id="eavEntityId"
        :selected-items="selectedItems"
        @input="toggleNested"
      />
      <BaseDialog
        label="Отчеты"
        is-cross-close
        hide-scroll
        v-model="dialogWithSettings"
        :max-width="800"
        @input="toggleNested"
      >
        <template #content>
          <PassportReports
            type="object"
            :value="value"
            :eav-entity="eavEntity"
            :selected-items="selectedItems"
          />
        </template>
      </BaseDialog>
      <BaseTable
        v-model="selectedItems"
        :loading="isLoading"
        :headers="headers"
        :items="items"
        multi-sort
        :options.sync="localQuery.options"
        :is-loading="isLoading"
        :item-key="'properties.id'"
        show-select
        v-if="countObjects"
        class="report-table"
        :all-items="objects"
      >
        <template #item="{item, isSelected}">
          <ListItemObject
            @input="handleSelectItem"
            :is-selected="isSelected"
            :headers="headers"
            :item="item"
            selectable
          />
        </template>
      </BaseTable>

      <v-card-title
        v-else
        class="justify-center"
      >
        Нет объектов
        <v-icon
          :color="eavEntity.eavEntity.style.color || 'green'"
          large
        >
          mdi-emoticon-sad-outline
        </v-icon>
      </v-card-title>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'
import BaseTable from '@/components/base/BaseTable'
import table from '@/components/mixins/table'
import { flatenObject, sortWithNull, verificationScenarioByRole } from '@/components/utils/common'
import ListItemObject from '@/components/views/account/map/detail/mapPassportDetail/reportTable/ListItemObject'
import PassportReports from '@/components/views/account/passport/detail/views/PassportReports'
import BaseBtn from '@/components/base/UI/BaseBtn'
import tableSelectedItems from '@/components/mixins/tableSelectedItems'
import BaseReportPanel from '@/components/base/BaseReportPanel'

export default {
  name: 'MapTableReport',
  components: { BaseBtn, BaseDialog, BaseTable, ListItemObject, PassportReports, BaseReportPanel },
  mixins: [table, tableSelectedItems],
  props: {
    controllerName: String,
    eavEntity: Object,
    value: Object,
    isDialog: Boolean,
    countObjects: Number,
    filter: Object,
    isSelectAll: Boolean
  },
  data () {
    return {
      selectedItems: [],
      isLoading: false,
      dialogWithSettings: false,
      dialogReport: false,
      isNestedDialog: false,
      objects: []
    }
  },
  watch: {
    reports: {
      handler () {
        this.selectedItems = this.reports[this.eavEntityId] || []
      },
      deep: true,
      immediate: true
    },
    countObjects: {
      handler () { // костыль
        return this.debounceFillData()
      },
      deep: true
    },
    filter: {
      handler () {
        this.$delete(this.localQuery, 'filter')
        this.$set(this.localQuery, 'filter', { ...this.defaultFilter, ...this.filter })
        this.debounceFillData()
      },
      deep: true
    }
  },
  computed: {
    eavEntityId () {
      return this.eavEntity?.eavEntity?.id
    },
    objectAttributesFlat () {
      return this.eavEntity.eavEntity.baseAttributes.concat(flatenObject(this.eavEntity.eavEntity.eavAttributes, 'relatedAttributes'))
    },
    reports () {
      return this.$store.getters['map/cart']
    },
    defaultFilter () {
      return {
        eav_entity_id: this.eavEntity.eavEntity.id,
        passport_id: this.value.properties.id,
        verificationScenario: verificationScenarioByRole()
      }
    },
    headers () {
      if (this.value && Object.keys(this.value).length) {
        return this.objectAttributesFlat
          .filter(item => item.show_in_table)
          .sort(sortWithNull('order'))
          .map(item => ({
            text: item.label,
            value: item.name,
            optionCategoryId: item.optionCategoryId,
            type: item.eavType.name,
            align: 'start',
            sortable: false,
            showTooltip: !!this.getLabelGroup(item),
            tooltipText: item.parent_id && this.getLabelGroup(item)
          }))
          .filter(item => item.type !== 'complexOption')
      }
    }
  },
  methods: {
    toggleMainDialog (event) {
      if (!this.isNestedDialog) {
        this.$emit('isDialog', event)
      }
    },
    toggleNested (event) {
      setTimeout(() => {
        this.isNestedDialog = event
      }, 0)
    },
    toggleDialogs (name, value) {
      this.$set(this, name, value)
      this.isNestedDialog = value
    },
    handleSelectItem (item) {
      if (item.isSelected) {
        this.$emit('handleAddToCart', item.item)
      } else {
        this.$emit('handleRemoveFromCart', item.item)
      }
      this.handleSelected(item)
    },
    getLabelGroup (item) {
      let result = ''
      const parentItem = this.objectAttributesFlat.find((element) => element.id === item.parent_id)
      if (parentItem?.eavType.id === 14) {
        result = 'Группа: ' + parentItem.label + '. \r\n \n ' + 'Атрибут: ' + item.label
      }
      return result
    },
    handleClear () {
      this.selectedItems = []
      this.$emit('clearSelected', this.value)
    }
  },
  beforeMount () {
    this.localQuery = {
      options: {
        filter: { ...this.defaultFilter },
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: []
      },
      filter: {
        ...this.defaultFilter,
        ...this.filter
      }
    }
  }
}
</script>

<style scoped>
  .report-table /deep/ .v-data-table .v-data-table__wrapper {
    max-height: calc(56vh - 22px) !important
  }
</style>
