<template>
  <BaseCard is-small-height>
    <template #content>
      <BaseImportUploader
        ref="uploader"
        v-model="params"
        :url="urlToUpload"
        :file-type="fileType"
        @geometry="handleGeometry"
        @uploaded="handleStart"
        :is-hide-manual-change="isHideManualChange"
      />
      <BaseParserDetails
        ref="detail"
        :eav-id="eavId"
        :pass-id="passId"
        :object-type="eavId ? 'object' : 'passport'"
        :params.sync="params"
        @parse:completed="handleParserCompleted"
      />
    </template>
  </BaseCard>
</template>

<script>
import BaseParserDetails from '@/components/base/BaseParserDetails'
import BaseImportUploader from '@/components/base/BaseImportUploader'
import BaseDialog from '@/components/base/BaseDialog'
import BaseCard from '@/components/base/BaseCard'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  name: 'MapImportPanel',
  components: { BaseCard, BaseImportUploader, BaseDialog, BaseParserDetails, BaseBtn },
  props: {
    urlToUpload: String,
    fileType: String,
    extraData: Object,
    isGeometry: Boolean,
    type: String,
    isHideManualChange: Boolean,
    isOpen: Boolean,
    eavId: Number,
    passId: Number
  },
  data () {
    return {
      params: this.extraData
    }
  },
  watch: {
    extraData: {
      handler () {
        this.params = this.extraData
      },
      deep: true
    }
  },
  methods: {
    handleParserCompleted () {
      this.$emit('parse:completed')
    },
    handleComplete () {
      this.$refs.detail.check()
    },
    handleGeometry () {
      this.$emit('geometry')
    },
    handleStart (data) {
      this.params.params.fileName = data.fileName
      this.params.params.crs = data.crs
      this.$refs.detail.start()
    }
  },
  mounted () {
    if (this.isOpen) {
      this.$refs.detail.check()
    }
  }
}
</script>

<style scoped>

</style>
