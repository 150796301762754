// note: файл для методов геометрии, которые мы генерим сами из кастомных кнопок. Рисование, редактирование, отмена последнего действия
export default {
  data () {
    return {
      currentActiveEditTool: null,
      currentActiveDrawTool: null,
      vertexes: [], // массив вертексов по текущей линии (draw),
      typeGeometryLayer: 'passport', // passport | object, текущий слой взаимодействия геометрии
      newConfigForCreate: {
        isCreate: true,
        type: 'object',
        item: null
      }
    }
  },
  methods: {
    //EDIT:
    activateMove () {
      this.currentActiveEditTool = 'move'
      this.commonActiveEdit()
      if (this.info.layer.pm.enabled()) this.info.layer.pm.disable()
      if (this.info.layer.pm.rotateEnabled()) this.info.layer.pm.disableRotate()
      this.info.layer.pm.enableLayerDrag()
    },
    activateVector () {
      this.currentActiveEditTool = 'vertex'
      this.commonActiveEdit()
      if (this.info.layer.pm.rotateEnabled()) this.info.layer.pm.disableRotate()
      this.info.layer.pm.enable()
    },
    activateRotate () {
      this.currentActiveEditTool = 'rotate'
      this.commonActiveEdit()
      if (this.info.layer.pm.enabled()) this.info.layer.pm.disable()
      this.info.layer.pm.enableRotate()
    },
    activateCut() {
      this.currentActiveEditTool = 'cut'
      this.commonActiveEdit()
      if (this.info.layer.pm.enabled()) this.info.layer.pm.disable()
      this.map.pm.enableGlobalCutMode()
    },
    commonActiveEdit () { //общие методы для редактирования
      if (this.map.pm.globalDrawModeEnabled()) {
        this.map.pm.disableDraw()
      }
      if (!this.map.pm.globalDragModeEnabled()) {
        this.modelStatic.findModelByLayer(this.info.layer)._removeCurrentDragMarker()
      }
    },

    // DRAW:
    activatePoint () {
      this.currentActiveDrawTool = 'point'
      this.map.pm.enableDraw('CircleMarker', {})
    },
    activateLineString () {
      this.currentActiveDrawTool = 'line'
      this.map.pm.enableDraw('Line', {})
    },
    activatePolygon () {
      this.currentActiveDrawTool = 'polygon'
      this.map.pm.enableDraw('Polygon', {})
    },
    activateRectangle () {
      this.currentActiveDrawTool = 'rectangle'
      this.map.pm.enableDraw('Rectangle', {})
    },
    removeLastVertex () {//удалить последний вертекс(линию) или "отмена последнего действия"
      if (this.vertexes.length) {
        const layer = this.vertexes[this.vertexes.length - 1].layer //сама линия
        const marker = this.vertexes[this.vertexes.length - 1].marker //её крайний маркер
        marker.remove()
        layer._latlngs.splice(layer._latlngs.length - 1, 1) //удалить линию
        layer.setLatLngs(layer._latlngs) //обновить её данные, чтобы визуально отобразилось изменение
        this.vertexes.splice(this.vertexes.length - 1, 1) //удалить вертекс из массива, поскольку мы его удалили с карты
      }
    },
    onAddVertex (e) { //обработчик на добавление вершины линии (вертекса)
      this.vertexes.push({
        layer: e.layer,
        marker: e.marker
      })
    }
  }
}