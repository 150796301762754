export default {
  methods: {
    //note: Кирилл. _ - служебный метод, чтобы названия не пересекались с computed
    _countReportGroups (groups) {
      //note: Кирилл. Лишние движения, аргумент решает вопрос разных путей к passportEntity
      const cart = this.$store.getters['map/cart']
      return groups?.filter(passportEntity => cart && cart[passportEntity.eavEntity.id]?.length).length
    }
  }
}
