export default {
  computed: {
    classByPassportType () {
      return {
        'green-areas': this.detail.properties.type === 1,
        'cemetery': this.detail.properties.type === 2,
        'forest': this.detail.properties.type === 3,
        'specially-protected-area': this.detail.properties.type === 4,
        'advertising-structures': this.detail.properties.type === 5,
        'trading-points': this.detail.properties.type === 6,
        'landscaping': this.detail.properties.type === 7,
        'locations-waste-storage': this.detail.properties.type === 8,
        'cadastral-plan': this.detail.properties.type === 9,
        'default': !this.detail.properties.type
      }
    }
  }
}