import { required } from 'vuelidate/lib/validators'

export default {
  validations: {
    detail: {
      properties: {
        label: required
      },
      geometry: {
        type: {
          required
        }
      }
    }
  }
}
