import offsetCopiedObject from '@/components/mixins/mobile/map/offsetCopiedObject'
import { defineDeepArray } from '@/components/utils/common'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'

export default {
  mixins: [offsetCopiedObject],
  data() {
    return {
      lastZoom: 15
    }
  },
  watch: {
    zoom: {
      handler () {//изменение радиуса точек. при отдалении - маленькие, поближе - крупнее
        this.updateRadiusObjects()
      }
    }
  },
  methods: {
    updateRadiusObjects () {
      const isNeedChangeStyle = this.lastZoom <= 19 && this.zoom >= 19 || this.lastZoom >= 19 && this.zoom <= 19 //рубежи зумов, чтоб не фигачить forEach на каждый зум
      if (this.controlLayers?.overlay?.length && isNeedChangeStyle) {
        this.controlLayers.overlay.forEach((overlay) => {
          overlay.children.forEach((item) => {
            const leafletid = item.layer._leaflet_id
            if (item.label === 'Point') {
              const layer = this.map._layers[leafletid]
              if (this.zoom <= 19) {
                layer && layer.setStyle({ radius: 1 })
              } else {
                layer && layer.setStyle({ radius: isMobileDevice() ? 9 : 5 })
              }
            }
          })
        })
      }
      this.lastZoom = this.zoom
    },
    rulerControl (e) {
      this.$store.commit('map/rulerChange', e)
    },
    handleStartLoading () {
      this.info.isLoading = true
    },
    handleEndLoading () {
      this.info.isLoading = false
    },
    changeCollapse () {
      this.$store.commit('map/changeCollapse')
    },
    deleteObject (data) {
      this.handleInfoDelete(this.info, data)
    },
    toggleGeometryMode (data) { //включить режим редактирования (через попап)
      this.typeGeometryLayer = 'object'
      this.isGeometry = true
      this.isCreate = false //редактируем, не создаем
      this.currentIdCreatingMapObject = data.detail.data.id //ставлю id, чтобы поменялась иконка, если мы находимся в непрерывном режиме создания другого типа объекта.
      let model = this.modelStatic.findModelByLayer(this.info.layer)
      if (this.isGeometry) {
        model.setEdit(true)
        if (this.info.detail.geometry.type === 'Point') {
          this.activateMove()
        }
      } else {
        model.setEdit(false)
      }
    },
    closeTotalGeometryHeader () { //полное закрытие геометрии, чтобы после обычного редактирования не открылось снова рисование
      this.$set(this.newConfigForCreate, 'item', null)
      this.closeGeometryHeader()
    },
    closeGeometryHeader () { // обработка закрытия геометрии
      if (!this.map.pm.globalDragModeEnabled()) {
        this.modelStatic.findModelByLayer(this.info.layer)._removeCurrentDragMarker()
      }
      if (this.map.pm.globalDrawModeEnabled()) {
        this.map.pm.disableDraw()
      }
      if (this.info.layer) {
        if (this.info.layer.pm.enabled()) this.info.layer.pm.disable() //вертекс
        if (this.info.layer.pm.rotateEnabled()) this.info.layer.pm.disableRotate()
        if (this.info.layer.pm.layerDragEnabled()) this.info.layer.pm.disableLayerDrag()
      }
      this._closeGeometry()
      this.vertexes = []
      this.currentActiveDrawTool = null
      this.typeGeometryLayer = 'passport'
      this.currentActiveEditTool = null
    },
    closeCurrentDraw () {
      const saveDraw = this.newConfigForCreate.item //делаю сохранение открытой геометрии
      this.closeGeometryHeader(true) //сначала закрыть по текущему объекту
      if (!saveDraw) return
      this.toggleGeometryAppBar(this.newConfigForCreate) // создать новый по этому же типу (например цветник)
      this.isCreate = true
    },
    async cancelDrawObject () {
      this.closeCurrentDraw()
      await this.handleInfoDelete(this.info)
    },
    disableRotateMode () {
      if (this.map.pm.globalRotateModeEnabled()) {
        this.map.pm.disableGlobalRotateMode()
      }
    },
    disableDragMode () {
      if (this.map.pm.globalDragModeEnabled()) {
        this.map.pm.disableGlobalDragMode()
      }
    },
    disableEditVectorMode () {
      if (this.map.pm.globalEditModeEnabled()) {
        this.map.pm.disableGlobalEditMode()
      }
    },
    async copyObject (event) {
      const detail = event?.detail?.detail
      const side = event.detail.side
      this.map.closePopup() //закрываю текущий открытый объект
      const isPolygonWithHole = (detail.geometry.type === 'Polygon' && detail.geometry.coordinates.length > 1)
      if (detail.geometry.type !== 'MultiPolygon' && !isPolygonWithHole) {
        const defineTripleArray = defineDeepArray({ arr: detail.geometry.coordinates, depth: 1 })
        if (side === 'right' || side === 'left') {
          defineTripleArray.depth === 2 ?
            detail.geometry.coordinates[0] = this.offsetHorizontal(detail.geometry, side, detail.geometry.type, defineTripleArray.arr)
            : detail.geometry.coordinates = this.offsetHorizontal(detail.geometry, side, detail.geometry.type, defineTripleArray.arr)
        } else if (side === 'top' || side === 'bottom') {
          defineTripleArray.depth === 2 ?
            detail.geometry.coordinates[0] = this.offsetVertical(detail.geometry, side, detail.geometry.type, defineTripleArray.arr)
            : detail.geometry.coordinates = this.offsetVertical(detail.geometry, side, detail.geometry.type, defineTripleArray.arr)
        }
      } else if (side !== 'center') {
        detail.geometry.coordinates = this.offsetMultiPolygon(side, detail.geometry.coordinates, detail.geometry.type)
      }

      this.modelStatic.handleDrawCreated({ type: 'Feature', ...detail }, this.info) //нарисовать

      await this.handleLayerClick(event.detail) //выбрать
      this.activateMove() //активировать перемещение
      this.typeGeometryLayer = 'object'
    },
    addListenersPopup () { //note: add слушатели с попапом пихать сюда
      document.addEventListener('object:close', this.handleInfoClose)
      document.addEventListener('save', this.handleInfoSave)
      document.addEventListener('delete', this.deleteObject)
      document.addEventListener('isLoading:start', this.handleStartLoading)
      document.addEventListener('isLoading:end', this.handleEndLoading)
      document.addEventListener('object:geometry', this.toggleGeometryMode)
      document.addEventListener('copy', this.copyObject)
      document.addEventListener('saveAfterImageOpen', this.saveAfterImageOpen)
    },
    removeListenersPopup () { //note: remove слушатели с попапом пихать сюда
      document.removeEventListener('object:close', this.handleInfoClose)
      document.removeEventListener('save', this.handleInfoSave)
      document.removeEventListener('delete', this.deleteObject)
      document.removeEventListener('isLoading:start', this.handleStartLoading)
      document.removeEventListener('isLoading:end', this.handleEndLoading)
      document.removeEventListener('object:geometry', this.toggleGeometryMode)
      document.removeEventListener('copy', this.copyObject)
      document.removeEventListener('saveAfterImageOpen', this.saveAfterImageOpen)
    },
    cutFinished () {
      this.currentActiveEditTool = null
    }
  },
  computed: {
    isCollapse () {
      return this.$store.getters['map/isCollapseMapInfo']
    },
    map () {
      return this.$refs.map.mapObject
    },
    isLoading () {
      return this.info.isLoading
    },
    entityGeometry () {
      if (this.isCreate && this.typeGeometryLayer !== 'passport') {
        if (this.info?.schema?.entityGeometry) {
          return this.info.schema.entityGeometry
        } else if (this.showingData && this.showingData.schemas) {
          return this.showingData.schemas[this.modelType.entityId].entityGeometry
        } else {
          return null
        }
      } else return undefined
    }
  }
}