<template>
  <div>
    <Header
      v-if="!isGeometry"
      @close="$emit('close', { detail: detail, type: type })"
      @click="openDialog"
      @verify="handleVerificationSend"
      :title="detail.properties.object_name ? detail.properties.object_name : 'Паспорт без названия'"
      :class="classByPassportType"
      :is-loading="isLoading || verificationLoading"
      :disabled="type === 'object'"
      :buttons="items"
      :can-verify="canVerify"
      :current-role="currentRole"
      @changeScenario="$emit('changeScenario', $event)"
      closeBtnTitle="Выйти из паспорта"
    />
    <MapObjectPanel
      v-show="detail.properties && detail.properties.passportEntities.length"
      :passport="detail"
      :objects="detail.properties.passportEntities"
      :filter="filter"
      @reportDialog="$emit('reportDialog', $event)"
      @geometry="handleObjectPanelGeometry"
      @hideItem="$emit('hideItem', $event)"
      @showFilters="$emit('showFilters', $event)"
      @resetFilter="$emit('resetFilter', $event)"
      @blockLayer="$emit('blockLayer', $event)"
      @massMove="$emit('massMove', $event)"
      @saveMassMoved="$emit('saveMassMoved', $event)"
      @importDialog="$emit('importDialog', $event)"
      :is-disable-interaction="isDisableInteraction"
      :currentIdCreatingMapObject="currentIdCreatingMapObject"
    />
    <BaseDialog
      v-model="dialog"
      :label="titleDialog"
      hide-scroll
      is-cross-close
      max-width="80%"
      :without-bot-padding="isImgDocsWindow"
      :without-sides-padding="isInfoWindow"
      :is-nested-dialog="isOpenNestedDialog"
    >
      <template #subtitle>
        <v-card-subtitle
          v-if="isInfoWindow && detail.properties.region"
          class="pa-0 ma-0"
        > {{ detail.properties.region.fullLabel }} <br>
          {{ formatValue('passport.type', Number(detail.properties.type)) }}
        </v-card-subtitle>
      </template>
      <template #content>
        <component
          v-model="detail"
          :is="selectedComponent"
          :is-open="dialog"
          model-name="passport"
          :pass-id="detail.properties.id"
          :model-id="detail.properties.id"
          name-object-doc="passportFiles"
          name-object-img="passportImages"
          :is-loading="isLoading"
          accept-types="application/pdf, .docx, .doc, .xls, .xlsx, .jpg, .jpeg, .png"
          :disabled="!isCanLoad(selectedTitle) && !isCanInteractionPassport"
          :file-type="fileType"
          :next-step-start-after="nextStepStartAfter"
          :previous-step-end-after="previousStepEndAfter"
          :params-to-upload="paramsToUpload"
          @geometry="handleGeometryAppBar"
          @save="handleSave"
          @delete="deleteDialog = !deleteDialog"
          with-description
          @update:dialog="dialog = $event"
          @toggleDialogNested="isOpenNestedDialog = $event"
          @updateLayers="updateLayers"
        >
        </component>
      </template>
    </BaseDialog>
    <BaseDialog
      role="delete"
      v-model="deleteDialog"
      @confirm="handleDelete"
      @input="deleteDialog = false"
      :max-width="400"
      is-confirm-btn
      is-cancel-btn
      :label="$t('passport.deletePassport') + '?'"
    >
    </BaseDialog>
    <BaseDialog
      label="Верификация"
      text="Отправить данные паспорта на проверку?"
      @confirm="verificationInDialog"
      v-model="isDialogVerify"
      is-confirm-btn
      is-cancel-btn
      text-cancel="Закрыть"
      :max-width="450"
    />
  </div>
</template>

<script>
import '@/css/passportTypes.css'
import Header from '@/components/views/account/map/detail/mapPassportDetail/Header'
import BaseDialog from '@/components/base/BaseDialog'
import MapPassportObjectsList
  from '@/components/views/account/map/detail/mapInfo/mapPassportInfo/MapPassportObjectsList'
import validationRulesPassport from '@/components/mixins/validation/passport'
import detail from '@/components/mixins/map/info/detail'
import verification from '@/components/mixins/verification'
import userInteraction from '@/components/mixins/userInteraction'
import PassportLayers from '@/components/views/account/passport/detail/views/PassportLayers'
import MapObjectPanel from '@/components/views/account/map/detail/mapPassportDetail/MapObjectPanel'
import L from 'leaflet'
import formatters from '@/components/mixins/formatters'
import classByPassportType from '@/components/views/account/map/classByPassportType'

const OBJECT_TYPE = 'geometry'
const COMMAND_TYPE = 'parsing'


export default {
  name: 'MapPassportDetail',
  mixins: [validationRulesPassport, detail, verification, userInteraction, formatters, classByPassportType],
  components: {
    Header,
    BaseDialog,
    MapPassportObjectsList,
    MapPassportEdit: () => import('@/components/views/account/map/detail/mapInfo/mapPassportInfo/MapPassportEdit'),
    PassportReports: () => import('@/components/views/account/passport/detail/views/PassportReports'),
    MapPhoto: () => import('@/components/views/account/map/detail/mapPassportDetail/MapPhoto'),
    MapDocuments: () => import('@/components/views/account/map/detail/mapPassportDetail/MapDocuments'),
    PassportLayers: () => import('@/components/views/account/passport/detail/views/PassportLayers'),
    MapObjectPanel,
    PassportInfo: () => import('@/components/views/account/map/detail/mapPassportDetail/PassportInfo'),
    BaseImportPanel: () => import('@/components/base/baseImportPanel/BaseImportPanel')
  },
  props: {
    value: Object,
    filter: Object,
    type: String,
    schema: Object,
    isGeometry: Boolean,
    isDisableInteraction: Boolean,
    currentIdCreatingMapObject: {
      default: null
    }
  },
  data () {
    return {
      items: [],
      dialog: false,
      selectedTitle: '',
      selectedComponent: null,
      detail: this.value,
      deleteDialog: false,
      fileType: 'geojson',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        commandType: COMMAND_TYPE,
        params: {
          objectType: OBJECT_TYPE,
          passportId: this.value.properties.id
        }
      },
      isOpenNestedDialog: false
    }
  },
  computed: {
    controllerName () {
      return 'passport'
    },
    titleDialog () {
      let result = !this.isImgDocsWindow ? this.selectedTitle : null
      if (this.isInfoWindow) {
        result = this.detail.properties.object_name || 'Паспорт без названия'
      }
      return result
    },
    isInfoWindow () {
      return this.selectedTitle === 'Информация о паспорте'
    },
    isImgDocsWindow () {
      return ['Изображения', 'Документы'].includes(this.selectedTitle)
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    },
    'detail.passportEntities': {
      handler () {
        this.initCart()
      },
      deep: true,
      immediate: true
    },
    isGeometry: {
      handler () {
        this.getItems()
      },
      deep: true,
      immediate: true
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  methods: {
    updateLayers () {
      this.$emit('updateLayers')
    },
    async getItems () {
      // обязательно должны быть указаны роли
      return this.items = [
        { title: 'Геометрия', icon: 'mdi-draw', roles: ['admin'] },
        { title: 'Типы объектов', icon: 'mdi-layers-triple-outline', roles: ['admin', 'manager'] },
        { title: 'Реквизиты паспорта', icon: 'mdi-pencil', roles: ['admin', 'manager'] },
        { title: 'Слои', icon: 'mdi-layers-search-outline', roles: ['admin', 'manager'] },
        { title: 'Информация о паспорте', icon: 'mdi-alert-circle', roles: ['unit', 'observer', 'auditor', 'manager'] },
        { title: 'Изображения', icon: 'mdi-file-image', roles: ['admin', 'unit', 'observer', 'auditor', 'manager'] },
        {
          title: 'Документы',
          icon: 'mdi-file-document-multiple',
          roles: ['admin', 'unit', 'observer', 'auditor', 'manager']
        },
        { title: 'Отчеты', icon: 'mdi-file-multiple', roles: ['admin', 'unit', 'observer', 'auditor', 'manager'] }
      ]
    },
    openDialog (title) {
      this.selectedTitle = title
      switch (title) {
        case 'Геометрия':
          this.selectedComponent = 'BaseImportPanel'
          break
        case 'Типы объектов':
          this.selectedComponent = 'MapPassportObjectsList'
          break
        case 'Реквизиты паспорта':
          this.selectedComponent = 'MapPassportEdit'
          break
        case 'Информация о паспорте':
          this.selectedComponent = 'PassportInfo'
          break
        case 'Слои':
          this.selectedComponent = 'PassportLayers'
          break
        case 'Изображения':
          this.selectedComponent = 'MapPhoto'
          break
        case 'Документы':
          this.selectedComponent = 'MapDocuments'
          break
        case 'Отчеты':
          this.selectedComponent = 'PassportReports'
          break
        default:
          this.selectedComponent = null
          break
      }
      this.dialog = true
    },
    initCart () {
      this.$store.commit('map/initCart', this.detail.properties.passportEntities.map(entity => entity.eavEntity.id))
    },
    handleGeometryAppBar () {
      this.$emit('geometry')
      this.dialog = false
    },
    handleObjectPanelGeometry (data) {
      if (!this.isGeometry) {
        this.$emit('geometry', data)
      }
    },
    addObjectPanel () {
      let mainBlock = document.querySelector('.leaflet-bottom.leaflet-right')
      if (mainBlock) {
        let blockToAdd = document.querySelector('.object-panel')
        if (blockToAdd) {
          blockToAdd.classList.add('leaflet-control')
          blockToAdd.addEventListener('mousewheel', L.DomEvent.stopPropagation)
          L.DomEvent.disableClickPropagation(blockToAdd)
          mainBlock.appendChild(blockToAdd)
        }
      }
    },
    destroyObjectPanel () {
      let mainBlock = document.querySelector('.leaflet-bottom.leaflet-right')
      if (mainBlock) {
        let blockToAdd = mainBlock.querySelector('.object-panel')
        if (blockToAdd) {
          blockToAdd.classList.remove('leaflet-control')
          blockToAdd.removeEventListener('mousewheel', L.DomEvent.stopPropagation)
          mainBlock.removeChild(blockToAdd)
        }
      }
    }
  },
  mounted () {
    this.addObjectPanel()
  },
  beforeDestroy () {
    this.destroyObjectPanel()
  }
}
</script>

<style>
  .leaflet-draw-actions a {
    background: #000;
  }
  .leaflet-draw-actions a:hover {
    background: #000;
    opacity: 0.8;
  }
  .leaflet-bottom {
    z-index: 999 !important;
  }
  .leaflet-div-icon {
    border-radius: 50%;
    width: 3px !important;
    height: 3px !important;
    margin-left: -5px !important;
    margin-top: -5px !important;
  }
  #map {
    height: 100%;
    position: relative;
  }
  .map-info {
    z-index: 99999999 !important;
  }
  .btn-chevron {
    position: fixed;
    top: 0;
    border-radius: 0 !important;
    background-color: #ffffff;
    z-index: 1;
    transition: margin-left 1s;
  }
  .collapseContent {
    margin-left: -40vw;
    transition: margin-left 1s;
  }
  .zeroingMargin {
    /*for collapse - for content and btn*/
    margin-left: 0;
    transition: margin-left 1s;
  }
  .noCollapseBtn {
    margin-left: 40vw;
    transition: margin-left 1s;
  }
  .cutMapInfo {
    height: 52px;
    overflow: hidden;
  }
  path.leaflet-interactive:focus {
    outline: none;
  }
</style>

<style scoped>
  .controls-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
  }
</style>

