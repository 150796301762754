<template>
  <div>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      rounded
      height="3"
      color="primary"
      style="z-index: 5"
    >
    </v-progress-linear>
    <component
      :is="componentClass"
      :value="value"
      :schema="schema"
      @save="$emit('save', $event)"
      @delete="$emit('delete', $event)"
      @close="$emit('close', $event)"
      @input="$emit('update:type', 'mapPassportNavigationSideBar')"
    />
  </div>
</template>

<script>

import MapObjectInfo from '@/components/views/account/map/detail/mapInfo/MapObjectInfo'
import MapRegionInfo from '@/components/views/account/map/detail/mapInfo/MapRegionInfo'
import MapPassportNavigationSideBar from '@/components/views/account/map/detail/MapPassportNavigationSideBar'

export default {
  name: 'MapInfo',
  components: { MapObjectInfo, MapRegionInfo, MapPassportNavigationSideBar },
  props: {
    value: Object,
    type: String,
    schema: Object,
    isLoading: Boolean
  },
  computed: {
    componentClass () {
      let component = null
      switch (this.type) {
        // case 'object':
        //   component = MapObjectInfo
        //   break
        // case 'passport':
        //   component = MapPassportInfo
        //   break
        case 'region':
          component = MapRegionInfo
          break
        case 'mapPassportNavigationSideBar':
          component = MapPassportNavigationSideBar
      }
      return component
    }
  }
}
</script>

<style scoped>

</style>
