<template>
  <div>
    <BaseDialog
      label="Фильтр"
      :value="isDialog"
      @input="$emit('isDialog', $event)"
      :max-width="800"
      hideScroll
      is-cross-close
    >
      <template #content>
        <BaseFilter
          :is-empty-filter="isEmptyFilter"
          @handleSearch="handleSearch"
          @clearFilter="handleClear"
          :is-loading="isLoading"
        >
          <template #filters>
            <v-form-base
              v-if="Object.keys(schema).length !== 0"
              :model="model"
              :schema="schema"
              @input="handleInput($event)"
            />
          </template>
        </BaseFilter>
      </template>
    </BaseDialog>
    <BaseDialog
      :max-width="500"
      is-cancel-btn
      is-confirm-btn
      :label="$t('base.attention')"
      :value="isWarningWindow"
      @confirm="handleSearchFromWindow"
      @input="isWarningWindow = false"
      :text="$t('message.deleteReportType') + ' ' + $t('message.continue') + '?'"
    />
  </div>
</template>

<script>
import VFormBase from 'vuetify-form-base'
import { cleanClone, flatenObject } from '@/components/utils/common'
import BaseDialog from '@/components/base/BaseDialog'
import BaseFilter from '@/components/base/BaseFilter'
import filter from '@/components/mixins/filter'
import { filterBaseAttrs } from '@/config/common'

export default {
  name: 'MapObjectFilter',
  components: { VFormBase, BaseDialog, BaseFilter },
  mixins: [filter],
  props: {
    value: Object,
    isDialog: Boolean,
    schemaObject: Object,
    filterObject: Object // changesField
  },
  watch: {
    filterObject: {
      handler () {
        if (this.filterObject && Object.keys(this.filterObject).length) {
          this.changesField = this.filterObject
          for (const key in this.filterObject) {
            this.$nextTick(() => {
              this.$set(this.model, key, this.filterObject[key])
            })
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      schema: {
        withFiles: {
          'type': 'checkbox',
          'min': null,
          'max': null,
          'dataType': 'string',
          'label': 'С документами',
          'name': 'withFiles',
          'color': 'primary',
          'isMultiple': false,
          'categoryId': null,
          'catId': null,
          'prependInnerIcon': '',
          'appendIcon': null,
          'dense': true,
          'outlined': true,
          'clearable': true,
          'emptyValue': false,
          'col': {
            'cols': 10
          }
        }
      },
      detail: cleanClone(this.value),
      model: {},
      changesField: {},
      keyFilterSchema: 1,
      isWarningWindow: false
    }
  },
  computed: {
    attributes () {
      const filteredBase = this.schemaObject.eavEntity.baseAttributes.filter(item => filterBaseAttrs.includes(item.name))
      return cleanClone(filteredBase.concat(this.schemaObject.eavEntity.eavAttributes))
    },
    isLoading () {
      return this.$store.getters['map/info'].isLoading
    },
    currentCart () {
      return this.$store.getters['map/cart'][this.schemaObject.eavEntity.id]
    }
  },
  methods: {
    handleInput (data) {
      if (data.data) {
        const changesItem = { [data.key]: data.value }
        this.$set(this, 'changesField', { ...this.changesField, ...changesItem })
        this.$emit('change', this.changesField)
      }
    },
    handleClear () {
      this.$emit('clear', this.changesField.eav_entity_id)
      for (const key in this.model) {
        this.$set(this.model, key, undefined)
      }
      this.keyFilterSchema += 1
    },
    handleSearchFromWindow () {
      this.isWarningWindow = false
      this.$emit('search', this.changesField)
      this.$store.commit('map/clearCart', this.schemaObject.eavEntity.id)
    },
    handleSearch () {
      if (this.currentCart.length > 0) {
        this.isWarningWindow = true
      } else {
        this.$emit('search', this.changesField)
      }
    },
    buildSchema () {
      const types = {
        'text': 'attributeTextField',
        'text_area': 'attributeTextField',
        'number': 'rangeOption',
        'option': 'searchSelect',
        'multipleOption': 'searchSelect',
        'complexOption': 'searchSelect'
      }
      let flatAttributes = flatenObject(this.attributes, 'relatedAttributes').filter((item) => item.eavType.id !== 14 && item.eavType.id !== 9)

      flatAttributes.map(item => {
        if (item.is_filter) {
          const iRulesObject = !!item.attributeRule.rules[1]

          const propsRange = {
            min: iRulesObject ? Number(item.attributeRule.rules[1].min) : NaN,
            max: iRulesObject ? Number(item.attributeRule.rules[1].max) : NaN
          }

          // this.model[item.name] = item.defaultValue
          this.$set(this.model, item.name, item.defaultValue)

          const icon = item.eavType.name === 'option' ? 'mdi-menu-down' : item.eavType.name === 'multipleOption' ||
          item.eavType.name === 'complexOption' ? 'mdi-magnify' : null

          let schemaItem = {
            type: types[item.eavType.name],
            ...propsRange,
            dataType: item.attributeRule.rules[0],
            label: item.label,
            name: item.name,
            color: 'primary',
            isMultiple: item.eavType.name === 'multipleOption',
            categoryId: item.optionCategoryId,
            catId: item.optionCategoryId,
            relatedAttributes: item.relatedAttributes,
            prependInnerIcon: '',
            appendIcon: icon,
            dense: true,
            outlined: true,
            clearable: false,
            emptyValue: item.eavType.name === 'option',
            col: { cols: 12 },
            eavEntityId: this.schemaObject.eavEntity.id
          }
          this.$set(this.schema, item.name, schemaItem)
        }
      })
    }
  },
  mounted () {
    this.buildSchema()
    if (Object.keys(this.schema).length === 0) {
      this.$emit('emptyField', true)
    }
  }
}
</script>
