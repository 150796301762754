import { decimal } from 'vuelidate/lib/validators'
// валидация для добавления элемента по координатам (компонент: AddCoordinates)
export default {
  methods: {
    validatePolygon (coordinates) {
      let valid = true
      if (!coordinates.length || !coordinates[0].every(item => item.length >= 2 && this.validateLatLng(item[0], item[1]))) {
        valid = false
      }
      return valid
    },
    validatePolyLine (coordinates) {
      let valid = true
      if (!coordinates.length || !coordinates.every(item => item.length >= 2 && this.validateLatLng(item[0], item[1]))) {
        valid = false
      }
      return valid
    },
    validatePoint (coordinates) {
      let valid = true
      if (coordinates.length !== 2 || !coordinates.every(item => decimal(item))) {
        valid = false
      }
      return valid
    },
    validateLatLng (lat, lng) {
      return isFinite(lng) && isFinite(lat) &&
        Math.abs(lat) <= 90 && Math.abs(lng) <= 180
    }
  }
}