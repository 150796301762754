<template>
  <v-app-bar
    class="ml-3 mr-3 app-bar"
    :color="color"
    height="44"
    tile
    app
    absolute
  >
    <slot name="cancelButton">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div
            class="caption d-block"
            v-on="on"
          >
            <v-icon
              class="cancel-button"
              color="white"
              :disabled="disabled"
              @click="handleClose"
            >
              mdi-close
            </v-icon>
          </div>
        </template>
        {{ 'Выйти из паспорта' }}
      </v-tooltip>
    </slot>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          class="white--text ellipsis-text"
        >
          {{ title }}
        </div>
      </template>
      {{ title }}
    </v-tooltip>
    <v-spacer/>
    <v-tooltip bottom>
      <template
        v-slot:activator="{ on }"
      >
        <div v-on="on">
          <VerificationBtn
            :can-verify="canVerify"
            :loading="isLoading"
            @click="handleVerificationSend"
          />
        </div>
      </template>
      {{ 'Отправить на верификацию' }}
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-select
            :items="itemsScenario"
            item-text="title"
            item-value="id"
            class="pl-8 pr-8 mt-0 white--text"
            outlined
            hide-details
            dark
            dense
            v-model="currentScenario"
          ></v-select>
        </div>
      </template>
      {{ 'Изменить сценарий' }}
    </v-tooltip>
    <div
      class="d-flex flex-row"
      v-for="(button, index) in filteredButtons"
      :key="index"
    >
      <BaseBtn
        v-if="button.if === false ? button.if : true"
        color="white"
        colorIcon="white"
        small
        x-small-icon
        text
        :class-btn="button.class ? button.class + ' pa-1' : 'pa-1'"
        :disabled="disabled"
        :loading="isLoading"
        :icon="button.icon"
        :title="button.title"
        @click="$emit('click', button.title)"
      />
      <slot name="append"></slot>
    </div>
  </v-app-bar>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import VerificationBtn
  from '@/components/views/account/passport/detail/views/passportDashboard/commonInfo/VerificationBtn'
import { verificationScenarioByRole } from '@/components/utils/common'

export default {
  name: 'Header',
  props: {
    color: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    buttons: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    currentRole: {
      type: String
    },
    isLoading: Boolean,
    closeBtnTitle: String,
    canVerify: Boolean
  },
  components: { VerificationBtn, BaseBtn },
  data () {
    return {
      itemsScenario: [
        { title: 'Верифицированные', id: 'origin' },
        { title: 'Актуальные', id: 'commit' },
        { title: 'Измененные', id: 'diff' }
      ],
      scenario: verificationScenarioByRole()
    }
  },
  computed: {
    currentScenario: {
      get () {
        return this.scenario
      },
      set (val) {
        this.scenario = val
        this.$emit('changeScenario', val)
      }
    },
    filteredButtons () {
      return this.buttons.filter(button => button.roles.includes(this.currentRole))
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    handleVerificationSend () {
      this.$emit('verify')
    }
  }
}
</script>

<style scoped>
  .cancel-button {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 0;
    margin-right: 8px;
    border: none;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
  }
  .app-bar {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
</style>
