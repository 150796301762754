<template>
  <BaseDialog
    :max-width="300"
    :value="localShow"
    label="Укажите тип объекта"
    is-cross-close
    @input="handleCancel"
  >
    <template #content>
      <v-select
        dense
        outlined
        label="Тип"
        :value="localSelected"
        @input="handleSelect"
        :items="entitiesList"
        item-value="id"
        item-text="entityName"
      >
      </v-select>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'SelectPassportObject',
  components: { BaseDialog },
  props: {
    show: Boolean,
    schemas: Object
  },
  data () {
    return {
      localSelected: null
    }
  },
  computed: {
    localShow: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    },
    entitiesList () {
      let list = []
      for (let schema in this.schemas) {
        list.push({ id: schema, entityName: this.schemas[schema].entityName })
      }
      return list
    }
  },
  methods: {
    async handleSelect (data) {
      this.$emit('input', data * 1)
      this.localSelected = data
      return this.handleClose()
    },
    async handleCancel () {
      this.$emit('cancel')
      return this.handleClose()
    },
    async handleClose () {
      this.localShow = false
      return this.$nextTick(() => this.localSelected = null)
    }
  }
}
</script>

<style scoped>

</style>
