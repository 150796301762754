<template>
  <v-app-bar
    class="ml-10 mr-10 app-bar"
    height="44"
    tile
    app
    dark
    absolute
  >
    <v-btn
      icon
      color="white"
      @click="$emit('close')"
    >
      <v-icon :color="(isGeometry && isCreate) ? 'white' : '#2daafa'">
        {{ isCreate ? 'mdi-close' : 'mdi-content-save-move-outline' }}
      </v-icon>
    </v-btn>
    <div
      v-if="isGeometry && !isCreate"
      class="d-flex flex-row"
    >
      <v-list-item
        v-if="!isPassport"
        :disabled="currentActiveEditTool==='move'"
        @click="$emit('activateMove')"
      >
        <v-icon :disabled="currentActiveEditTool==='move'">mdi-drag-variant</v-icon>
      </v-list-item>
      <v-list-item
        v-if="typeGeometry !== 'Point' && typeGeometry !== 'LineString'"
        :disabled="currentActiveEditTool==='cut'"
        @click="$emit('activateCut')"
      >
        <v-icon :disabled="currentActiveEditTool==='cut'">mdi-content-cut</v-icon>
      </v-list-item>
      <v-list-item
        v-if="typeGeometry !== 'Point'"
        :disabled="currentActiveEditTool==='vertex'"
        @click="$emit('activateVector')"
      >
        <v-icon :disabled="currentActiveEditTool==='vertex'">mdi-vector-point-select</v-icon>
      </v-list-item>
      <v-list-item
        v-if="typeGeometry !== 'Point' && !isPassport"
        :disabled="currentActiveEditTool==='rotate'"
        @click="$emit('activateRotate')"
      >
        <v-icon :disabled="currentActiveEditTool==='rotate'">mdi-rotate-3d-variant</v-icon>
      </v-list-item>
    </div>
    <div
      v-else-if="isGeometry && isCreate"
      class="d-flex flex-row"
    >
      <p
        class="white--text ma-0"
        v-if="!availableTypesGeo.length && !isPassport"
      > нет доступных типов </p>
      <v-list-item
        :disabled="currentActiveDrawTool==='point'"
        @click="$emit('activatePoint')"
        v-if="!isPassport && availableTypesGeo.includes('Point')"
      >
        <v-icon :disabled="currentActiveDrawTool==='point'">mdi-vector-point-select</v-icon>
      </v-list-item>
      <v-list-item
        :disabled="currentActiveDrawTool==='line'"
        @click="$emit('activateLineString')"
        v-if="!isPassport && availableTypesGeo.includes('LineString')"
      >
        <v-icon :disabled="currentActiveDrawTool==='line'">mdi-vector-line</v-icon>
      </v-list-item>
      <v-list-item
        :disabled="currentActiveDrawTool==='polygon'"
        @click="$emit('activatePolygon')"
        v-if="isPassport || availableTypesGeo.includes('Polygon')"
      >
        <v-icon :disabled="currentActiveDrawTool==='polygon'">mdi-vector-polygon</v-icon>
      </v-list-item>
      <v-list-item
        :disabled="currentActiveDrawTool==='rectangle'"
        @click="$emit('activateRectangle')"
        v-if="isPassport || availableTypesGeo.includes('Polygon')"
      >
        <v-icon :disabled="currentActiveDrawTool==='rectangle'">mdi-vector-rectangle</v-icon>
      </v-list-item>

      <v-btn
        icon
        @click="$emit('removeLastVertex')"
        dark
        v-if="['line', 'polygon'].includes(currentActiveDrawTool)"
      >
        <v-icon>
          mdi-arrow-left-top
        </v-icon>
      </v-btn>
    </div>
    <v-icon
      v-if="isGeometry && !isCreate && currentActiveDrawTool"
      color="red"
      @click="isCancelObjectDialog = true"
      class="ml-7"
    >mdi-close
    </v-icon>
    <BaseDialog
      max-width="400"
      label="Стереть объект?"
      is-confirm-btn
      v-model="isCancelObjectDialog"
      is-cancel-btn
      with-label
      @confirm="confirmCancelObject"
    ></BaseDialog>
  </v-app-bar>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'GeometryHeader',
  components: { BaseDialog },
  props: {
    isGeometry: Boolean,
    isCreate: Boolean,
    currentActiveEditTool: String,
    currentActiveDrawTool: String,
    typeGeometry: String,
    isPassport: Boolean,
    entityGeometry: [String, null]
  },
  data () {
    return {
      isCancelObjectDialog: false
    }
  },
  computed: {
    availableTypesGeo () {
      return this.entityGeometry ? this.entityGeometry.split(',') : []
    }
  },
  methods: {
    confirmCancelObject () {
      this.$emit('cancelDrawObject')
      this.isCancelObjectDialog = false
    }
  }
}
</script>