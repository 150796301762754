<template>
  <div v-if="detail">
    <DetailView v-model="detail"/>
  </div>
</template>

<script>
import DetailView from '@/components/views/account/region/detail/DetailView.vue'


export default {
  name: 'MapEdit',
  components: { DetailView },
  props: {
    value: Object
  },
  data () {
    return {
      detail: this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
