export default {
  methods: {
    offsetHorizontal (geometry, side = 'right', type = 'Polygon', coord) {
      let coordinates = coord
      if (type === 'Point') {
        if (side === 'right') {
          coordinates[0] += 0.00003
        } else {
          coordinates[0] -= 0.00003
        }
      } else {
        const width = this._findRectangleWidth(coordinates)
        if (side === 'right') {
          coordinates = coordinates.map(function (point, index) {
            return [point[0] + width, point[1]]
          })
        } else {
          coordinates = coordinates.map(function (point, index) {
            return [point[0] - width, point[1]]
          })
        }
      }
      return coordinates
    },
    offsetVertical (geometry, side = 'top', type = 'Polygon', coord) {
      let coordinates = coord
      if (type === 'Point') {
        if (side === 'top') {
          coordinates[1] += 0.00002
        } else {
          coordinates[1] -= 0.00002
        }
      } else {
        const height = this._findRectangleHeight(coordinates)
        if (side === 'top') {
          coordinates = coordinates.map(function (point, index) {
            return [point[0], point[1] + height]
          })
        } else {
          coordinates = coordinates.map(function (point, index) {
            return [point[0], point[1] - height]
          })
        }
      }
      return coordinates
    },
    offsetMultiPolygon (side = 'right', coord = [], type) {
      let coordinates = coord
      coordinates = coord.map((polygons, index) => {
          const isPolygonWithHole = polygons.length > 1
          if (isPolygonWithHole && type !== 'Polygon') { //для мультиполигона
            return polygons.map((item, i) => { //его полигончики
                const isHoles = i > 0 //если это не 0 элемент, то это дырка, остальные - полигоны
                if (!isHoles && type !== 'Polygon') {
                  if(side === 'right' || side === 'left') return this.offsetHorizontal(null, side, type, item) //для 0 элемента, т.е. полигона обычный сдвиг
                  if(side === 'top' || side === 'bottom') return this.offsetVertical(null, side, type, item)
                } else if (isHoles && type === 'MultiPolygon') {
                  const originPolygon = polygons[0] // 0 элемент - это всегда сам полигон, все остальные - его дырки
                  return this._offsetHole(item, originPolygon, side)
                }
              }
            )
          } else if (type === 'Polygon') { //обычный полигон с дыркой
            if (index === 0) {
              if(side === 'right' || side === 'left') return this.offsetHorizontal(null, side, type, polygons) //для 0 элемента, т.е. полигона обычный сдвиг
              if(side === 'top' || side === 'bottom') return this.offsetVertical(null, side, type, polygons)
            } else {
              const originPolygon = coordinates[0] // 0 элемент - это всегда сам полигон, все остальные - его дырки
              return this._offsetHole(polygons, originPolygon, side)
            }
          } else {
            if(side === 'right' || side === 'left') return [this.offsetHorizontal(null, side, type, polygons[0])]
            if(side === 'top' || side === 'bottom') return [this.offsetVertical(null, side, type, polygons[0])]
          }
        }
      )
      return coordinates
    },
    _offsetHole (polygons, originPolygon, side) {
      let hole = []
      const width = this._findRectangleWidth(originPolygon)
      const height = this._findRectangleHeight(originPolygon)
      switch (side) {
        case 'right':
          hole = polygons.map(function (point) {
            return [point[0] + width, point[1]]
          })
          break
        case 'left':
          hole = polygons.map(function (point) {
            return [point[0] - width, point[1]]
          })
          break
        case 'top':
          hole = polygons.map(function (point) {
            return [point[0], point[1] + height]
          })
          break
        case 'bottom':
          hole = polygons.map(function (point) {
            return [point[0], point[1] - height]
          })
          break
      }
      return hole
    },
    _findRectangleWidth (coordinates) {
      let minLon = coordinates[0][0]
      let maxLon = coordinates[0][0]
      for (let i = 1; i < coordinates.length - 1; i++) {
        if (coordinates[i][0] < minLon) {
          minLon = coordinates[i][0]
        }
        if (coordinates[i][0] > maxLon) {
          maxLon = coordinates[i][0]
        }
      }
      return maxLon - minLon //ширина, на которую нужно переместить объект в сторону
    },
    _findRectangleHeight (coordinates) {
      let minLat = coordinates[0][1]
      let maxLat = coordinates[0][1]
      for (let i = 1; i < coordinates.length - 1; i++) {
        if (coordinates[i][1] < minLat) {
          minLat = coordinates[i][1]
        }
        if (coordinates[i][1] > maxLat) {
          maxLat = coordinates[i][1]
        }
      }
      return maxLat - minLat
    }
  }
}