<template>
  <BaseDialog
    :value="isDialog"
    @input="$emit('isDialog', $event)"
    :max-width="500"
    :label="$t('map.addCoordinates')"
    is-cross-close
    is-cancel-btn
    :is-confirm-btn="!!typeObject && !!coordinates"
    :text-cancel="$t('base.cancel')"
    :text-confirm="$t('base.add')"
    @confirm="addElement"
  >
    <template #content>
      <p class="ma-0">Выберите тип:</p>
      <v-checkbox
        v-for="item in types"
        :label="item.label"
        :key="item.value"
        hide-details
        dense
        class="ma-0 black--text"
        v-model="typeObject"
        :value="item.value"
        @change="typeObject = $event"
      ></v-checkbox>
      <p class="ma-0 mt-2">Введите координаты соответствующие типу объекта. {{ $t('map.formatCoordinates') }} </p>
      <p
        v-if="typeObject !== 'Point' && typeObject"
        class="ma-0 grey--text"
      >{{ $t('message.enterList') }}</p>
      <v-textarea
        outlined
        hide-details
        :height="typeObject === 'Point' ? 20 : 'auto'"
        dense
        no-resize
        v-model="coordinates"
        :placeholder="typeObject === 'Point' ? 'XX.XXXXXX,XX.XXXXXX' : placeholder"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'
import GeometryFactory from '@/components/structures/geometryFactory'
import validateGeometry from '@/components/mixins/map/validateGeometry'

export default {
  components: { BaseDialog },
  mixins: [validateGeometry],
  name: 'AddCoordinates',
  props: {
    isDialog: Boolean,
    eavId: Number
  },
  data () {
    return {
      typeObject: null,
      coordinates: null,
      types: [
        { value: 'Point', label: this.$t('map.marker') },
        { value: 'LineString', label: this.$t('map.polyline') },
        { value: 'Polygon', label: this.$t('map.polygon') }
      ],
      placeholder: 'XX.XXXXXX,XX.XXXXXX' + '\n' +
        'XX.XXXXXX,XX.XXXXXX'
    }
  },
  methods: {
    formatCoordinates () {
      let geometry = new GeometryFactory({ type: this.typeObject, coordinates: this.coordinates })
      geometry.coordinates = geometry.coordinates.filter(n => n)
      this.coordinates = geometry.coordinates
      let valid = false
      switch (this.typeObject) {
        case 'Polygon':
          valid = this.validatePolygon(this.coordinates)
          break
        case 'Point':
          valid = this.validatePoint(this.coordinates)
          break
        case 'LineString':
          valid = this.validatePolyLine(this.coordinates)
          break
      }
      return valid
    },
    addElement () {
      if (this.formatCoordinates()) {
        const newObjectOnMap = {
          type: 'Feature',
          properties: {
            eav_entity_id: this.eavId
          },
          geometry: {
            coordinates: this.coordinates,
            type: this.typeObject
          }
        }
        this.$emit('add', newObjectOnMap)
        this.$emit('isDialog', false)
      } else {
        this.$store.dispatch('systemMessages/error', this.$t('message.noCorrectCoordinates'))
      }
    }
  }
}
</script>
