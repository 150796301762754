import user from '@/components/mixins/user'

export default {
  mixins: [user],
  methods: {
    selectFirst () {
      const modelStatic = this.$store.getters['map/modelStatic']
      if (this?.searchModel?.items?.models?.length && this.currentRole !== 'admin' && !modelStatic?.selected?.children) {
        const data = this.searchModel.items.models[0]
        setTimeout(() => {
          this.$emit('search:select', {
            detail: {
              data,
              type: 'passport',
              selected: false
            },
            options: {
              animate: false
            }
          })
        }, 0)
      }
    }
  }
}