<template>
  <v-card
    class="pa-0"
    flat
    height="100vh"
  >
    <div
      class="map-detail-new"
      :class="getDetailClass"
    >
      <GeometryHeader
        v-if="isGeometry"
        @close="closeGeometryHeader"
        :is-geometry="isGeometry"
        :is-create="isCreate"
        :type-geometry="info.detail ? info.detail.geometry.type : ''"
        :entity-geometry="entityGeometry"
        :current-active-edit-tool="currentActiveEditTool"
        :current-active-draw-tool="currentActiveDrawTool"
        @activateMove="activateMove"
        @activateVector="activateVector"
        @activateRotate="activateRotate"
        @activatePoint="activatePoint"
        @activateLineString="activateLineString"
        @activatePolygon="activatePolygon"
        @activateRectangle="activateRectangle"
        @removeLastVertex="removeLastVertex"
        @cancelDrawObject="cancelDrawObject"
        @activateCut="activateCut"
        :is-passport="typeGeometryLayer === 'passport'"
      />
      <MapPassportDetail
        v-if="info.layer"
        :key="objectPanelKey"
        :value="showingData.detail"
        :filter="info.filter"
        :schema="info.schema"
        :is-geometry="isGeometry"
        :type="info.type"
        :is-loading="info.isLoading"
        @geometry="handleCreateObject"
        @save="handleInfoSave"
        @close="handleInfoClose"
        @delete="handleInfoDelete(showingData)"
        @reportDialog="openReportTable"
        @hideItem="hideObjects"
        @showFilters="showFilters"
        @resetFilter="clearFilter($event.eavEntity.id)"
        @importDialog="showImportDialog"
        @updateLayers="handlePassportTileLayers"
        @blockLayer="blockLayer"
        @massMove="massMove"
        @saveMassMoved="saveMassMoved"
        @changeScenario="changeScenario"
        :is-disable-interaction="isGeometry"
        :current-id-creating-map-object="currentIdCreatingMapObject"
      />
      <MapTableReport
        v-if="itemActiveReport"
        :is-dialog="isReportDialog"
        @isDialog="isReportDialog = $event"
        controller-name="object"
        :eavEntity="itemActiveReport"
        :value="showingData.detail"
        :key="keyReportWindow"
        @handleAddToCart="handleAddReport($event, itemActiveReport.eavEntity.id)"
        @handleRemoveFromCart="handleDeleteReport($event, itemActiveReport.eavEntity.id)"
        :count-objects="countCurrentObjectTable"
        @clearSelected="clearReportObject"
        :filter="info.filter[itemActiveReport.eavEntity.id] ? info.filter[itemActiveReport.eavEntity.id] : {}"
        :is-select-all="true"
      />
      <!--      note: не удалять AddCoordinates! -->
      <!--      <AddCoordinates-->
      <!--        type="object"-->
      <!--        v-if="isAddCoordinatesWindow"-->
      <!--        :is-dialog="isAddCoordinatesWindow"-->
      <!--        @isDialog="isAddCoordinatesWindow = $event"-->
      <!--        :eav-id="modelType.entityId"-->
      <!--        @add="createByCoordinates"-->
      <!--      />-->
      <MapObjectFilter
        :is-dialog="isFiltersObjectsDialog"
        @isDialog="isFiltersObjectsDialog = $event"
        @search="searchObjects"
        @clear="clearFilter"
        :value="itemFilter"
        :schema-object="itemFilter"
        v-if="isFiltersObjectsDialog && itemFilter"
        :filter-object="info.filter[itemFilter.eavEntity.id]"
      />
      <BaseDialog
        v-model="isImportDialog"
        label="Менеджер импорта"
        hide-scroll
        is-cross-close
        :max-width="1200"
        without-sides-padding
        little-label
        without-bot-padding
      >
        <template #subtitle>
          <div class="text-h4 font-weight-bold"> {{ eavEntityName }}</div>
        </template>
        <template #content>
          <BaseImportPanel
            :file-type="fileType"
            :next-step-start-after="nextStepStartAfter"
            :previous-step-end-after="previousStepEndAfter"
            :params-to-upload="paramsToUpload"
            :is-hide-manual-change="true"
            @parse:completed="handleParseComplete"
          />
        </template>
      </BaseDialog>
    </div>
    <div
      v-if="info.detail === null && !isGeometry && !info.isLoading"
      class="map-passport-navigation"
    >
      <MapPassportNavigationSideBar
        :type.sync="info.type"
        :value="info.detail"
        @create="handleCreatePassport"
        @search:select="handleSearchSelect"
        :hiddenList="hiddenList"
        @hide="hidePassport"
      />
    </div>
    <l-map
      ref="map"
      :center.sync="center"
      :zoom.sync="zoom"
      :options="{zoomControl: false, drawControl: false}"
      style="z-index: 0"
      :max-zoom="24"
      id="main-map"
    >
      <PreloaderWindow
        width="60vw"
        :value="isLoading"
      />
      <div class="controls-container">
        <l-control-zoom position="bottomright"></l-control-zoom>
        <div ref="measureControl"></div>
      </div>
    </l-map>
  </v-card>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete'
import MapInfo from '@/components/views/account/map/detail/MapInfo'
import BaseHint from '@/components/base/UI/BaseHint'
import common from '@/components/mixins/map/common'
import mapInit from '@/components/mixins/map/helpers/mapInit'
import modelHandlers from '@/components/mixins/map/handlers/modelHandlers'
import SelectPassportObject from '@/components/views/account/map/detail/SelectPassportObject'
import mapStore from '@/components/mixins/map/store'
import BaseDialog from '@/components/base/BaseDialog'
import MapPassportDetail from '@/components/views/account/map/detail/MapPassportDetail'
import MapPassportNavigationSideBar from '@/components/views/account/map/detail/MapPassportNavigationSideBar'
import MapTableReport from '@/components/views/account/map/detail/mapPassportDetail/MapTableReport'
import report from '@/components/views/account/map/detail/mapPassportDetail/reportTable/report'
import workPanelMap from '@/components/mixins/workPanelMap'
import MapObjectFilter from '@/components/views/account/map/detail/mapPassportDetail/MapObjectFilter'
import AddCoordinates from '@/components/views/account/map/detail/mapPassportDetail/AddCoordinates'
import MapImportPanel from '@/components/views/account/map/detail/mapPassportDetail/MapImportPanel'
import user from '@/components/mixins/user'
import BaseImportPanel from '@/components/base/baseImportPanel/BaseImportPanel'
import { cleanClone } from '@/components/utils/common'
import chroma from 'chroma-js'
import PreloaderWindow from '@/components/base/UI/PreloaderWindow'
import mapDetail from '@/components/mixins/mobile/map/mapDetail'
import filterPassports from '@/components/views/account/map/detail/mapPassportNavigationSideBar/filterPassports'
import controlLeaflet from '@/components/mixins/map/helpers/controlLeaflet'
import GeometryHeader from '@/components/views/account/map/detail/GeometryHeader'

const PARSING_TYPE = 'object'
const COMMAND_TYPE = 'parsing'

/**
 * @typedef {import('vue').Ref<import('leaflet').Map|null>} L
 */
export default {
  mixins: [common, mapInit, modelHandlers, mapStore, report, workPanelMap, user, mapDetail, filterPassports, controlLeaflet],
  name: 'Detail',
  components: {
    BaseImportPanel,
    BaseDialog,
    MapPassportNavigationSideBar,
    BaseHint,
    MapInfo,
    BaseAutocomplete,
    SelectPassportObject,
    MapPassportDetail,
    MapTableReport,
    MapObjectFilter,
    AddCoordinates,
    MapImportPanel,
    PreloaderWindow,
    GeometryHeader
  },
  data () {
    return {
      isOpen: false,
      //todo: отрефакторить общий объект для создания объекта
      modelType: {
        type: null,
        entityId: null
      },
      isCreate: false,
      controlLayers: {
        measure: null,
        layers: null,
        tile: null,
        overlay: {},
        customTiles: null
      },
      isGeometry: false,
      countCurrentObjectTable: null, //количество объектов
      fileType: 'geojson',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        commandType: COMMAND_TYPE,
        params: {
          passportId: null,
          eavEntityId: null,
          parsingType: PARSING_TYPE
        }
      },
      currentIdCreatingMapObject: null,
      currentObjectName: null
    }
  },
  computed: {
    entityId () {
      let result = null
      if (this.currentImportObject) {
        result = this.showingData.detail?.properties.passportEntities[this.currentImportObject].eavEntity.id
      }
      return result
    },
    eavEntityName () {
      let result = null
      if (this.currentImportObject || this.currentImportObject === 0) {
        result = cleanClone(this.showingData.detail?.properties.passportEntities[this.currentImportObject].eavEntity.entityName)
      }
      return result
    },
    // :note Меняет название титльника при ручном вводе геометрии или создании новых объектов
    titleGeometryAppBar () {
      let result = 'Без названия'
      if (this.currentObjectName) {
        result = this.currentObjectName
      } else if (this.info?.detail?.properties.object_name) {
        result = this.info.detail.properties.object_name
      }
      return result
    },
    passId () {
      return this.showingData.detail?.properties.id
    },
    getDetailClass () {
      let result = ''
      if (this.isCollapse) {
        result += 'collapseContent '
      } else {
        result += 'zeroingMargin '
      }
      if (!this.$can('admin', null) && this.info.type === 'region') {
        result += 'cutMapInfo '
      }
      return result
    },
    showingData () { // data для отображения в карточке
      return this.info.type === 'object' ? this.modelStatic.getParentDetail() : this.info
    },
    isRulerActive () {
      return this.$store.getters['map/isRulerActive']
    },
    deleteObjectsTable () {
      return this.$store.getters['map/deleteObjectsTable']
    }
  },
  watch: {
    deleteObjectsTable: {
      handler () {
        if (this.deleteObjectsTable.length && this.deleteObjectsTable.include(this.info.detail.properties.id)) {
          location.reload()
        }
      },
      deep: true
    },
    itemActiveReport: {
      handler () {
        this.keyReportWindow += 1
      },
      deep: true
    },
    currentImportObject: {
      handler () {
        return this.$nextTick(() => {
          this.paramsToUploadUpdate()
        })
      }
    },
    currentRole: {
      handler (newVal, oldVal) {
        if (oldVal !== newVal && (oldVal === 'admin' || newVal === 'admin')) {
          return location.reload()
        }
      }
    }
  },
  methods: {
    async handlePassportTileLayers () {
      this.clearLayersControls()
      await this.initOverlayControls()
    },
    paramsToUploadUpdate () {
      let result = null
      if (this.currentImportObject || this.currentImportObject === 0) {
        result = this.showingData.detail?.properties.passportEntities[this.currentImportObject].eavEntity.id
      }
      this.paramsToUpload.params.passportId = this.showingData.detail?.properties.id
      this.paramsToUpload.params.eavEntityId = result
      return true
    },
    async countObjects (item) {
      const response = await this.$store.dispatch('server/get', { url: '/statistics?filter[passport_id][]=' + this.showingData.detail.properties.id }, { root: true })
      return response[this.showingData.detail.properties.type].objects[item.eavEntity.id].total_count
    },
    clearReportObject (event, schemaId = this.itemActiveReport.eavEntity.id) {
      const carts = this.$store.getters['map/cart'][schemaId]
      carts.forEach((item) => {
        const currentEavObject = this.modelStatic._getActiveModel(this.info.layer).selected.children.layers.geoJson.local[item.geometry.type][schemaId]
        for (let key in currentEavObject._layers) {
          currentEavObject._layers[key].setStyle(this.itemActiveReport.eavEntity.style)
        }
      })
      this.$store.commit('map/clearCart', schemaId)
    },
    async handleParseComplete () {
      this.isImportDialog = false
      const response = await this.$store.dispatch('server/get', {
        url: 'passport/detail/' + this.showingData.detail.properties.id
      })
      await this.handleInfoSave({ detail: response })
      return true
    },
    handleAddReport (event, schemaId) {
      this.handleAddToCart(event, schemaId) // в стор
      const currentLayer = this.findClickReportObject(event, schemaId)
      currentLayer.setStyle({ color: chroma(this.itemActiveReport.eavEntity.style.color).brighten().hex() })
    },
    handleDeleteReport (event, schemaId) {
      this.handleRemoveFromCart(event, schemaId)
      const currentLayer = this.findClickReportObject(event, schemaId)
      currentLayer.setStyle(this.itemActiveReport.eavEntity.style)
    },
    findClickReportObject (event, schemaId) { // возвращает слой, добавленный или удаленный из отчета
      const currentEavObject = this.modelStatic._getActiveModel(this.info.layer).selected.children.layers.geoJson.local[event.geometry.type][schemaId] //все слои по eav
      let currentLayer = null //слой, который добавился в отчеты
      for (let key in currentEavObject._layers) {
        if (currentEavObject?._layers[key]?.feature?.properties?.id === event?.properties?.id) {
          currentLayer = currentEavObject._layers[key]
        }
      }
      return currentLayer
    },
    handleCreatePassport (e) {
      this.typeGeometryLayer = 'passport'
      this.toggleGeometryAppBar(e)
    },
    handleCreateObject (e) {
      if (e?.isCreate) {
        this.typeGeometryLayer = 'object'
        this.toggleGeometryAppBar(e)
      } else {
        this.toggleGeometryAppBar(e)
      }
    },
    toggleGeometryAppBar (data) {
      //note Выполняется проверка  title шапки геометрии при раборте с объектом
      if (data && data.type === 'object' && data.isCreate) {
        this.currentObjectName = 'Новый объект'
      } else if (data && data.detail && data.detail?.data?.entityName) {
        this.currentObjectName = data.detail.data.entityName + ' № ' + data.detail.elementNumber
      }
      if (data && data.hasOwnProperty('isCreate')) {
        data.item && this.$set(this, 'currentIdCreatingMapObject', data.item.id)
        this.isCreate = data.isCreate
        if (data.item) {
          this.modelType.entityId = data.item.eavEntity.id
        }
        this.modelType.type = data.type
        this.map.closePopup()
      } else if (this.isCreate) {
        this.isCreate = false
        this.modelType.entityId = null
        this.modelType.type = null
        this.currentIdCreatingMapObject = null
      }
      this.isGeometry = !this.isGeometry

      let model = this.modelStatic.findModelByLayer(this.info.layer)
      if (this.isGeometry) {
        model.setEdit(true)
      } else {
        model.setEdit(false)
      }

      if (this.map.pm.globalDrawModeEnabled()) {
        this.map.pm.disableDraw()
      }
    },
    mountedMap () {
      this.scaleControl()
      this.initTileLayers()
      return this.$nextTick(async () => {
        await this.initModel()
        this.initMeasureControls()

        this.map.on({
          'pm:create': this.handlePmCreate,
          'pm:drawstart': this.handleDrawStart,
          'edit:disable': this._closeGeometry,
          'polylinemeasure:toggle': this.rulerControl,
          'cut:finished': this.cutFinished
        })

        document.addEventListener('layer:startLoading', this.handleStartLoading)
        document.addEventListener('layer:endLoading', this.handleEndLoading)
        document.addEventListener('search:select', this.handleSearchSelect)
        document.addEventListener('layer:click', this.handleLayerClick)
        document.addEventListener('enableEditCustom', this.toggleGeometryAppBar)
        this.addListenersPopup()
        if (this.info.layer) {
          this.objectPanelKey += 1
        }
        return true
      })
    },
    beforeDestroyMap () {
      this.map.off({
        'pm:create': this.handlePmCreate,
        'pm:drawstart': this.handleDrawStart,
        'edit:disable': this._closeGeometry,
        'polylinemeasure:toggle': this.rulerControl,
        'cut:finished': this.cutFinished
      })
      this.info.filter = {}
      document.removeEventListener('layer:startLoading', this.handleStartLoading)
      document.removeEventListener('layer:endLoading', this.handleEndLoading)
      document.removeEventListener('search:select', this.handleSearchSelect)
      document.removeEventListener('layer:click', this.handleLayerClick)
      document.removeEventListener('enableEditCustom', this.toggleGeometryAppBar)
      this.removeListenersPopup()
    }
  },
  async mounted () {
    await this.mountedMap()
  },
  async beforeDestroy () {
    await this.beforeDestroyMap()
  }
}
</script>

<style>
  .leaflet-draw-actions a {
    background: #000;
  }
  .leaflet-draw-actions a:hover {
    background: #000;
    opacity: 0.8;
  }
  .leaflet-bottom {
    z-index: 999 !important;
  }
  .leaflet-div-icon {
    border-radius: 50%;
    width: 3px !important;
    height: 3px !important;
    margin-left: -5px !important;
    margin-top: -5px !important;
  }
  #map {
    height: 100%;
    position: relative;
  }
  .map-info {
    z-index: 99999999 !important;
  }
  .btn-chevron {
    position: fixed;
    top: 0;
    border-radius: 0 !important;
    background-color: #ffffff;
    z-index: 1;
    transition: margin-left 1s;
  }
  .collapseContent {
    margin-left: -40vw;
    transition: margin-left 1s;
  }
  .zeroingMargin {
    /*for collapse - for content and btn*/
    margin-left: 0;
    transition: margin-left 1s;
  }
  .noCollapseBtn {
    margin-left: 40vw;
    transition: margin-left 1s;
  }
  .cutMapInfo {
    height: 52px;
    overflow: hidden;
  }
  path.leaflet-interactive:focus {
    outline: none;
  }
</style>

<style scoped>
  .controls-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
  }
</style>
