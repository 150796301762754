<template>
  <BaseMapInfo
    :label="detail.properties.label? detail.properties.label : 'Новый субъект'"
    :detail="detail"
  >
    <template #tooltips>
      <v-tooltip bottom>
        <template
          v-slot:activator="{ on }"
        >
          <div
            class="caption d-block"
            v-on="on"
          >
            <BaseBtn
              v-if="showNewSideBarButton"
              icon="mdi-eye-check"
              :disabled="!$can('admin', null)"
              @click="$emit('input', 'mapPassportNavigationSideBar')"
              :loading="isLoading"
              text
              color="primary"
              isTypeIcon
            />
          </div>
        </template>
        Перейти в панель "Список доступных паспортов"
      </v-tooltip>
      <v-tooltip bottom>
        <template
          v-if="isAllowSaveDelete"
          v-slot:activator="{ on }"
        >
          <div
            class="caption d-block"
            v-on="on"
          >
            <BaseBtn
              icon="mdi-content-save-outline"
              :disabled="!$can('admin', null)"
              @click="handleSave"
              :loading="isLoading"
              text
              color="primary"
              isTypeIcon
            />
          </div>
        </template>
        {{ $t('base.save') }}
      </v-tooltip>
      <v-tooltip
        bottom
      >
        <template
          v-if="isAllowSaveDelete"
          v-slot:activator="{ on }"
        >
          <div
            class="caption d-block"
            v-on="on"
          >
            <BaseBtn
              icon="mdi-delete-empty-outline"
              :disabled="!$can('admin', null)"
              @click="deleteDialog = !deleteDialog"
              text
              color="info"
              isTypeIcon
            />
          </div>
        </template>
        {{ $t('passport.deleteRegion') }}
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div
            class="caption d-block"
            v-on="on"
          >
            <BaseBtn
              icon="mdi-close"
              @click="handleClose"
              text
              color="info"
              isTypeIcon
            />
          </div>
        </template>
        {{ $t('passport.closePassport') }}
      </v-tooltip>
    </template>

    <template #tabs>
      <v-divider></v-divider>
      <v-tabs
        v-model="tab"
        vertical
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
          v-show="items.length > 1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div
                class="caption d-block "
                v-on="on"
              >
                <v-icon>{{ item.tab }}</v-icon>
              </div>
            </template>
            {{ item.hint }}
          </v-tooltip>
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <component
              v-model="detail"
              :is="item.content"
              :prepend-inner-icon="item.prependInnerIcon"
              :is-loading="item.isLoading"
            >
            </component>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </template>

    <template #dialog>
      <v-divider></v-divider>
      <BaseDialog
        role="delete"
        @confirm="handleDelete"
        v-model="deleteDialog"
        :text="$t('message.deleteRegion')"
        is-cancel-btn
        is-confirm-btn
        :max-width="350"
      />
    </template>
  </BaseMapInfo>
</template>

<script>
import detail from '@/components/mixins/map/info/detail'
import validationRulesRegion from '@/components/mixins/validation/region'
import MapRegionEdit from '@/components/views/account/map/detail/mapInfo/mapRegionInfo/MapRegionEdit'
import BaseMapInfo from '@/components/base/BaseMapInfo'
import BaseDialog from '@/components/base/BaseDialog'
import userInteraction from '@/components/mixins/userInteraction'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  mixins: [detail, validationRulesRegion, userInteraction],
  name: 'MapRegionInfo',
  components: {
    BaseBtn,
    MapRegionEdit,
    BaseMapInfo,
    BaseDialog
  },
  props: {
    value: Object
  },
  data () {
    return {
      deleteDialog: false,
      detail: this.value,
      tab: null,
      items: [],
      type: 'region'
    }
  },
  computed: {
    controllerName () {
      return 'region'
    },
    showNewSideBarButton () {
      return this.detail.properties.is_leaf === 1
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    destroyCart () {
      this.$store.commit('map/destroyCart')
    }
  },
  mounted () {
    this.destroyCart()
    this.items = [
      {
        tab: 'mdi-pencil',
        hint: this.$t('base.edit'),
        content: 'MapRegionEdit'
      }
    ]
  }
}
</script>

<style scoped>

</style>
